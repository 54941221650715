@use "bootstrap" as *;
@use "app.scss" as *;
@use "style.scss" as *;
@use "chat.scss" as *;
@use "call-center.scss" as *;
@use "theme.scss" as *;
@use "custom-animations.scss" as *;

@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");
@import "rc-time-picker/assets/index.css";
@import "react-treeview/react-treeview.css";
@import "react-day-picker/style.css";
@import "react-toggle/style.css";
@import "react-querybuilder/dist/query-builder.css";