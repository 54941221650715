@use 'sass:color';
@use "variables.scss" as *;

.text-theme,
.text-theme-hover a:hover,
.text-theme-hover .active>a {
  color: $primary;
}

bg-theme {
  background: $primary;
}

.bg-theme-gradient {
  background: linear-gradient($primary, $primary-dark);
}

.bg-theme-gradient-108 {
  background: linear-gradient(108deg, $primary, $primary-dark);
}

.b-theme {
  border-color: $primary;
}

.show-on-hover {
  .hover-element {
    display: none;
  }

  &:hover {
    .hover-element {
      display: block;
    }
  }
}


img {
  &.loading {
    background: transparent url("http://thinkfuture.com/wp-content/uploads/2013/10/loading_spinner.gif") center no-repeat;
  }
}

.element-group {
  >span {
    padding-right: 6px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.primary-text {
  color: rgba(0, 0, 0, 0.99);
}

.secondary-text {
  color: rgba(0, 0, 0, 0.66);
}

.editable-input {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: 13px;

  &:read-write:focus {
    outline: none;
  }
}


.custom-timer-style {
  input {
    border-radius: 0;
    height: 36px;
    border: 1px solid #78828ccc;
    font-size: 13px;
    color: black;
  }
}

.custom-rc-picker-popup {
  z-index: 10000 !important;
}

.custom-timer-style-theme {
  input {
    border-radius: 0;
    height: 26px;
    border: 1px solid #ddd;
    font-size: 13px;
    color: black;
  }

  .rc-time-picker-clear {
    top: 2px;
  }
}

.input-theme {
  padding: 3px 8px;
  border: 1px solid #ddd;
  font-size: 13px;
  height: 26px;
}

.test-slider {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-left: 1px solid black;
  border-right: 1px solid black;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    width: 100%;
    border: 1px solid black;
    transform: rotate(180deg);
  }
}

.image-wrapper-default {
  width: 100px;
  height: 65px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }

  &.border {
    border: 1px solid #eee;
  }
}

.product-image-wrapper {
  display: flex;

  .image-list {
    overflow: hidden;
    z-index: 2;

    .single-image {
      display: flex;
      justify-content: center;
      border: 1px solid #f0f0f0;
      border-top: none;
      width: 64px;
      height: 64px;
      cursor: pointer;
      background: white;

      &:first-child {
        border-top: 1px solid #f0f0f0;
      }

      &.active {
        border: 2px solid $primary;
      }

      img {
        display: block;
        margin: auto;
        max-width: 49px;
        max-height: 49px;
      }
    }
  }
}

.link-on-hover {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $primary;
  }

  &.link-warning {
    &:hover {
      color: tomato;
    }
  }

  &.no-underline {
    text-decoration: none;
  }
}

.custom-link {
  color: $primary;
  cursor: pointer;

  &.no-color {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

.link {
  text-decoration: underline;
  color: $primary;
  cursor: pointer;

  &.no-color {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
    color: $primary;
  }

  &.no-underline {
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer;
}

.button-circle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  border: none;
  color: #b3b3b3;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    color: $primary;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.09);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }

  &.button-edit {
    width: 30px;
    height: 30px;
    font-size: 16px;
    box-shadow: none;
    color: #7a7a7a;

    &:hover {
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.09);
    }

    &:active {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    }
  }
}

//button ends

.main-horizontal-wrapper {
  display: flex;
  height: 100vh;
  float: right;
  width: 100%;
  overflow-y: hidden;
}

.main-horizontal-wrapper::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f3f3f3;
}

.main-horizontal-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f5f5f5;
}

.main-horizontal-wrapper::-webkit-scrollbar-thumb {
  background-color: #d9d9de;
  box-shadow: inset 0 -2px, inset 0 -3px, inset 0 2px, inset 0 3px;
  color: #d9d9de;
  background-clip: padding-box !important;
}

.horizontal-scroll-wrapper {
  display: flex;
  flex: 1;
  white-space: nowrap;
}

.wrapper-box {
  display: inline-block;
  overflow-x: hidden;
  height: 100vh;
  border-right: 1px solid #ddd;
  white-space: normal;
  overflow-y: hidden;
}

.data-box {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-bottom: 14px;
  padding: 16px;
}

.hoverpointer:hover {
  cursor: pointer;
}

.hoverpointer:hover {
  cursor: pointer;
  background-color: #1dd38f !important;
  color: white !important;
}

.hoverpointer:hover button {
  background-color: white !important;
  color: #1dd38f !important;
}

.scrollbar {
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.08);
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box !important;
  }

  &.theme {
    &::-webkit-scrollbar-track {
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.08);
    }

    &::-webkit-scrollbar {
      display: none;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $primary;
      background-clip: padding-box !important;
    }
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
    }
  }
}

.pointer-text {
  cursor: pointer;
  color: #003fff;

  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

/* material design input button ------------------------------------------ */

.md-custom-input {
  position: relative;
  margin-bottom: 20px;

  &.round {
    input {
      border-radius: 4px;
    }
  }

  &.large {
    input {
      font-size: 21px;
      padding: 18px 18px 4px 12px;
    }

    label {
      font-size: 16px;
      top: 16px;
      font-weight: 300;
    }
  }

  input {
    width: 100%;
    font-size: 14px;
    padding: 16px 16px 4px 12px;
    display: block;
    border: 1px solid rgba(120, 130, 140, 0.8);
  }

  textarea {
    width: 100%;
    font-size: 14px;
    padding: 16px 16px 4px 12px;
    display: block;
    border: 1px solid rgba(120, 130, 140, 0.8);
  }

  :focus {
    outline: none;
    border: 1px solid $primary;
  }

  label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 12px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  :focus~label {
    top: 3px;
    left: 10px;
    font-size: 11px;
    color: $primary;
  }

  .phone-placeholder {
    display: none;
  }

  :focus~.phone-placeholder {
    position: absolute;
    padding: 0px 2px 0 13px;
    display: inline-block;
    bottom: 5px;
    border-right: 1px solid #ddd;
  }

  :valid~.phone-placeholder {
    position: absolute;
    padding: 0px 2px 0 13px;
    display: inline-block;
    bottom: 5px;
    border-right: 1px solid #ddd;
  }

  input:valid~label,
  input:read-only~label,
  textarea:valid~label {
    top: 3px;
    left: 10px;
    font-size: 11px;
  }

  .error {
    color: #c40000;
    font-size: 11px;
    font-weight: normal;
    position: absolute;
    padding: 2px 10px 0;
    display: none;
  }
}

.md-custom-input-error {
  margin-bottom: 25px;
  color: #c40000;

  input {
    border: 1px solid #c40000;
    background-color: rgb(253, 230, 235);
  }

  textarea {
    border: 1px solid #c40000;
  }

  :focus {
    border: 1px solid #c40000;
  }

  label {
    color: #c40000;
  }

  :focus~label,
  :valid~label {
    color: #c40000;
  }

  span {
    display: block;
  }

  .error {
    display: block;
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
  }
}

/* material design input button end------------------------------------------ */

.text-center {
  text-align: center;
}

.text-center-divider {
  position: relative;

  &::after {
    content: "";
    width: 100%;
    background-color: transparent;
    display: block;
    height: 1px;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    z-index: 1;
  }
}

/* pagination for user views------------------------------------------ */


.pagination-web {
  margin: 0;
  font-weight: 500;

  .paginator-arrow {
    font-size: 30px;
    color: $primary;
  }

  li>a,
  li>span {
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:hover {
    color: $primary;
  }

  .disabled {
    display: none;
  }
}

/* pagination for user views end------------------------------------------ */


/* navigatin profile sidebar start -----------------------------------------------*/

.nav-profile-sidebar {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  border: none;
  color: rgb(144, 148, 156);

  .nav-item {
    width: 100%;
    margin-bottom: 0;

    .nav-link {
      width: 100%;
    }
  }

  .nav-item+.nav-item {
    margin-left: 0px;
  }

  li {
    width: 100%;
    border-bottom: none;
    margin-bottom: 0px !important;
    font-size: 14px;
    line-height: 18px;
    border-left: 5px solid white;
    cursor: pointer;
  }

  li>a {
    width: 100%;
    height: 100%;
    padding: 10px 24px;
    display: flex;
    align-items: center;
  }

  li:not(.active):hover {
    color: black;
  }

  .active {
    font-weight: 600;
    background: #ebebeb;
    border-left: 5px solid rgb(255, 170, 35);

    a {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

/* navigatin profile sidebar end -----------------------------------------------*/

/* modern-nav start------------------------------------------ */

.nav-lean {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  height: 100%;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-left: solid 1px rgba(0, 0, 0, 0.1);
  color: rgb(144, 148, 156);

  .nav-item {
    width: 100%;
    margin-bottom: 0;

    .nav-link {
      width: 100%;
    }
  }

  .nav-item+.nav-item {
    margin-left: 0px;
  }

  li {
    width: 100%;
    border-bottom: none;
    margin-bottom: 0px !important;
    font-size: 12px;
    line-height: 18px;
    border-left: 5px solid transparent;
    cursor: pointer;
  }

  li>a {
    width: 100%;
    height: 100%;
    padding: 10px 24px;
    display: flex;
    align-items: center;
  }

  li:hover {
    color: rgba(0, 0, 0, 0.87);
  }

  .active {
    font-weight: 400;
    background: #ebebeb;
    border-left: 5px solid #ddd;

    a {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

/* modern-nav end------------------------------------------ */

/* navigation rounded start------------------------------------------ */

.nav-rounded {
  display: flex;
  align-items: flex-end;
  right: 1px;
  font-size: 16px;
  font-weight: 500;

  .nav-item {
    margin-bottom: 0;
  }

  li {
    background: #f2f2f2;
    border-radius: 10px 10px 0 0;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: none;
    margin-right: 7px;
    min-width: 120px;
    padding: 8px;
    height: 35px;
  }

  li>a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:not(.active):hover {
    background: #dedede;
  }

  .active {
    min-width: 124px;
    height: 38px;
    background: white;
  }
}

/* navigation rounded end------------------------------------------ */

/* navigation horizontal material design start------------------------------------------ */

/* navigation horizontal material design end------------------------------------------ */

/* navigation encloded design start------------------------------------------ */

.nav-enclosed {
  border-bottom: 1px solid #ddd;

  .nav-item {
    margin: 0;
    margin-bottom: -1px;
  }

  li {
    border: 1px solid transparent;
    border-bottom-color: #ddd;

    &.active {
      border: 1px solid #ddd;
      border-bottom-color: white;
    }
  }

  li>a {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    justify-content: center;
  }

  li:not(.active):hover {
    color: $primary;
  }

  li:not(.active) {
    color: $primary;
  }
}

.nav-panel {
  border: 1px solid #ddd;
  border-top: none;
  min-height: 300px;
}

/* navigation enclosed design end------------------------------------------ */

/* navigation header design start------------------------------------------ */

.nav-header {
  display: flex;
  align-items: flex-end;
  right: 1px;
  font-size: 16px;
  font-weight: 500;

  .nav-item {
    margin-bottom: 0;
  }

  li {
    background: white;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-right: none;
    min-width: 120px;
    padding: 8px;
    height: 35px;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-right: solid 1px rgba(0, 0, 0, 0.1);
      border-radius: 0px 3px 3px 0;
    }
  }

  li>a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:not(.active):hover {
    background: #dedede;
  }

  .active {
    background: $primary;
    color: white;
  }

  &.small {
    li {
      min-width: 80px;
      height: 24px;
    }
  }
}

/* navigation header design end------------------------------------------ */

.products-container {
  .products-row {
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    .product-card {
      padding: 20px 20px 20px 20px !important;
      border-right: 1px solid #eee;

      &.card-background {
        background-size: 100% 200px;
        background-position: left bottom;
        background-repeat: no-repeat;
        background-image: linear-gradient(to top,
            #fafafa 0,
            #fdfdfd 20%,
            #fff 60%);
      }

      .price-text {
        font-size: 16px;
        color: #ffaa23;
        letter-spacing: 0.8px;
        padding: 2px;
      }

      &:nth-child(4) {
        border-right: none;
      }
    }
  }
}

/* profile screen  start------------------------------------------ */


.option-list {
  margin-top: 5px;
  border: 1px solid #f4f4f4;
  border-bottom: 0;

  &.error {
    .error {
      display: block;
    }
  }

  .error {
    color: #c40000;
    font-size: 11px;
    font-weight: normal;
    padding: 2px 10px 0;
    display: none;
  }

  .option-list-item {
    display: flex;
    justify-content: flex-start;
    padding: 4px 8px;
    border-bottom: 1px solid #f4f4f4;
    font-size: 10px;
    border-radius: 2px;
    align-items: center;

    .image-wrapper {
      display: flex;
      justify-content: center;
      width: 60px;
      height: 30px;

      img {
        max-height: 100%;
        display: block;
        margin: auto;
      }
    }

    .text {
      text-transform: capitalize;
      font-size: 12px;
    }
  }

  .active {
    border: 1px solid $primary;
  }
}

/* profile screen  end------------------------------------------ */

/* box-nav-container ----------------------------------------------- */

.box-nav-container {
  position: relative;

  content {
    display: block;
    background-color: #fbfbfb;

    .content-wrapper {
      padding: 24px;
      background-color: #fff;
      min-height: 300px;
    }

    .content-wrapper-sm {
      max-width: 800px;
      margin: 0 auto;
    }

    .header-text {
      white-space: normal;
      padding-bottom: 24px;
    }
  }
}

.profile-list {
  .list-header {
    font-size: 12px;
    border-bottom: 1px solid #dddfe2;
    line-height: 26px;
    color: #90949c;
    font-weight: 500;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #1d2129;

    li {
      display: flex;
      padding: 12px 0;
      border-top: 1px solid #e9ebee;

      &:first-child {
        border: none;
      }

      &.active {
        background: #f5f6f7;
        padding: 12px 5px;
      }

      .heading {
        color: #90949c;
        margin-right: 18px;
        width: 180px;
      }

      .content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .edit {
          display: flex;
          align-items: center;
          font-size: 12px;
          cursor: pointer;

          i {
            font-size: 11px;
            margin-right: 2px;
            color: $primary;
            letter-spacing: 0.8px;
          }
        }
      }

      .action-text {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 16px;
          margin-top: -1px;
          margin-right: 5px;
        }
      }
    }
  }
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;

  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #eaeff0;

    .label-required {
      position: relative;
    }

    .label-required:after {
      content: "*";
      position: absolute;
      top: -3px;
      right: -10px;
      color: #e68686;
    }


    &.list-active {
      background: #dfffe4;
    }

    .list-toolbar {
      font-size: 14px;
      align-items: center;
      opacity: 0;
    }

    &:hover {
      .list-toolbar {
        opacity: 1;
      }
    }
  }

  &:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &.details-row-container {
    .list-group-item {
      span {
        :first-child {
          margin-right: 20px;
        }
      }
    }

    .list-group-item:not(.no-flex) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list-item-text {
    flex: 1;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;

    .description {
      font-size: 11px;
      white-space: normal;
      font-weight: 400;
    }
  }

  .list-item-body {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: flex-start;

    .radio-item {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 4px 0;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;

      input {
        margin-right: 8px;
        margin-top: 2px;
      }

      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: none;

  &.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  .panel-heading {
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
  }

  &.panel-default {
    border-color: #eaeff0;

    .panel-heading {
      color: #333;
      background-color: #f5f5f5;
      border-color: #eaeff0;

    }
  }

  .list-group-container {
    display: flex;
    width: 100%;

    .list-group:not(:first-child) {
      border-left: 1px solid #eaeff0;
    }
  }

  .list-group {
    margin-bottom: 0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
    }
  }

  .panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0;
  }
}

.form-horizontal {
  .form-control-label {
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 0;
    text-align: right;
    font-weight: 500;
  }

  .label-required:after {
    content: "*";
    position: absolute;
    top: 6px;
    right: 3px;
    color: #e68686;
  }

}

td .label-required:after {
  content: "*";
  position: absolute;
  top: -4px;
  right: -10px;
  color: #e68686;
}

.wizard-header {
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.03em;
}

.ui-check {
  &.tick {
    i::before {
      display: none;
    }

    i {
      display: flex;
      border: 1px solid #ddd;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      color: transparent;
    }

    i::selection {
      background: none;
    }

    input:checked+i {
      color: $primary;
    }

    input:indeterminate+i {
      color: $primary;
    }
  }
}

.modal-wrapper {
  position: absolute;
  background: rgba(33, 33, 33, 0.48);
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-flow: column;
  padding-top: 50px;
  align-items: center;
  z-index: 9999;

  .modal-new {
    width: 400px;
    background: #fff;
    border-radius: 0.3rem;
    position: relative;

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;
    }
  }
}

.opaque-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 999;
    background: rgba(33, 33, 33, 1);
    opacity: 0.48;
    transition: opacity 450ms;
  }
}

.md-wrapper-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .md-wrapper {
    opacity: 0;
    display: flex;
    background: #fff;
    min-width: 140px;
    max-width: 80%;
    position: relative;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    flex-direction: column;

    .wrapper-header {
      font-size: 20px;
      font-weight: 400;
      padding: 24px;
    }

    .wrapper-body {
      padding: 0px 24px 24px;
      font-size: 12px;
    }

    .wrapper-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 16px;
    }
  }

  &.active {
    z-index: 1000;

    .md-wrapper {
      opacity: 1;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform: translate(0, 0) scale(1);
    }
  }
}

.notification {
  width: 300px;
  height: 120px;
  box-shadow: rgba(224, 224, 224, 0.87) 0px 0px 6px 3px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 16px;
}

.icon-badge {
  padding: 0 5px;
  font-size: 10px;
  margin: 0 5px;
  border-radius: 2px;
  color: #1000ff;
  border: 1px solid #1000ff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;

  &.color-red {
    color: red;
    border-color: red;
  }
}

.icon-badge-md {
  padding: 2px 6px;
  font-size: 12px;
  margin: 0 5px;
  border-radius: 2px;
  color: #1000ff;
  border: 1px solid #1000ff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;

  &.color-red {
    color: red;
    border-color: red;
  }
}

.icon-badge-lg {
  padding: 4px 8px;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 2px;
  color: #1000ff;
  border: 1px solid #1000ff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;

  &.color-red {
    color: red;
    border-color: red;
  }
}

.hover-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: $primary;
}

// product page design
.product-aggregations {
  width: 230px;
  padding: 8px 0 8px 8px;

  &.fullpage {
    width: 249px;
  }
}

.aggregation-containers {
  position: relative;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .aggregation-header {
    padding: 13px;

    .header-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.3px;

      .clear-all-text {
        font-size: 12px;
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .aggregation-list {
    .aggregation-title {
      padding: 8px 16px;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.3px;
      }
    }

    .aggregation-items {
      cursor: pointer;
      padding: 0px 16px 8px;

      .clear-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0px;
        line-height: 1;

        .clear-icon {
          display: inline-block;
          height: 14px;
          width: 14px;
          background-color: #f0f0f0;
          padding: 0;
          font-size: 13px;
          text-align: center;
          color: #878787;
          vertical-align: middle;
        }

        .item-text {
          padding: 0px 0px 0px 11px;
          color: #878787;
        }
      }

      .aggregation-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0px;
        line-height: 1;

        &.input-box {
          flex-flow: column;
          align-items: flex-start;
        }

        .item-text {
          padding: 0px 0px 0px 11px;
          flex: 1;

          .count {
            font-size: 10px;
            letter-spacing: 0.6px;
          }
        }
      }

      .more-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0px;
        line-height: 1;

        .item-text {
          font-size: 12px;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }
}

.aggregation-modal {
  width: 800px;
  height: 400px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    background-color: #fafafa;
    padding: 12px 0;

    .header-title {
      padding: 8px 30px;
      text-transform: uppercase;
    }

    .header-search {
      width: 80%;
      display: flex;
      align-items: center;

      input {
        margin: 0;
        padding: 10px;
        width: 198px;
        height: 32px;
      }
    }

    .close-icon {
      margin: 0 20px;
    }
  }

  .modal-container {
    overflow-x: scroll;
    border-radius: 2px;
    height: 288px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 18px 24px 20px 24px;
    align-content: flex-start;

    .modal-item-header {
      width: 200px;
      display: flex;
      align-items: center;
      padding: 8px 0px;
      line-height: 1;

      .item-text {
        font-weight: 600;
        flex: 1;
      }
    }

    .modal-item {
      width: 200px;
      display: flex;
      align-items: flex-end;
      padding: 8px 2px 8px 0;
      line-height: 1;

      input {
        position: absolute;
        top: 0;
        right: 0;
      }

      .item-text {
        padding: 0px 0px 0px 11px;
        flex: 1;

        .count {
          font-size: 10px;
          letter-spacing: 0.6px;
        }
      }
    }
  }

  .virtualized-modal-container {
    border-radius: 2px;

    .modal-list {
      padding: 18px 12px 20px 12px;
    }

    .modal-item-header {
      width: 224px;
      display: flex;
      align-items: center;
      padding: 8px 0px;
      line-height: 1;

      .item-text {
        font-weight: 600;
        flex: 1;
      }
    }

    .modal-item {
      width: 200px;
      display: flex;
      align-items: flex-end;
      padding: 8px 2px 8px 0;
      line-height: 1;

      input {
        position: absolute;
        top: 0;
        right: 0;
      }

      .item-text {
        padding: 0px 0px 0px 11px;
        flex: 1;

        .count {
          font-size: 10px;
          letter-spacing: 0.6px;
        }
      }
    }
  }

  .modal-footer {
    height: 56px;
    padding: 9px 0;

    .footer-options {
      display: flex;
      float: right;
      padding: 0 20px;

      .option-item {
        padding: 8px 30px;

        span {
          text-transform: uppercase;
        }
      }
    }
  }
}

.products-search-container {
  padding: 8px;
  flex: 1;

  .highlight {
    background: #ffc;
  }

  .search-box-wrapper {
    position: relative;
    border-radius: 3px;

    .search-box {
      margin-bottom: 8px;
      padding: 20px;

      .search-input {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.23);
        display: inline-flex;
        width: 100%;
        border-radius: 4px;

        input {
          display: flex;
          width: 92%;
          border-radius: 4px;
          padding: 0 10px;
          border: none;
        }

        button {
          border-radius: 0px 4px 4px 0px;
          width: 8%;
          height: 36px;
          background-color: #ffe11b;
        }

        .search-icon {
          font-size: 20px;
        }
      }
    }
  }
}

.seller-profile {
  display: flex;
  flex-flow: column;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);

  .profile-header {
    height: 41px;
    display: flex;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }

  .close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
    background: $primary;
    z-index: 999;
    border-radius: 50%;
    padding: 2px;
    font-size: 20px;
    cursor: pointer;
  }
}

.search-result-wrapper {
  display: flex;
  flex-flow: column;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);

  .paginate-text {
    padding: 12px 16px 0;

    h3 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .sort-nav {
    margin-top: 8px;
    padding: 0 16px;

    .sort {
      float: right;
    }
  }
}



.product-element-horizontal {
  display: flex;
  padding: 16px 0;
  position: relative;

  .checkbox-wrapper {
    display: flex;
    position: relative;
    margin: 10px;

    .label {
      margin-right: 10px;

      input: {
        display: none;
      }
    }
  }

  .image-wrapper {
    width: 100px;
    height: 65px;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: auto;
    }
  }

  .text-wrapper-container {
    display: flex;
    width: 100%;

    .text-wrapper {
      padding: 0px 10px;
      width: 100%;

      .title {
        font-size: 14px;
        display: flex;
        font-weight: 500;
      }

      .secondary-text {
        font-size: 13px;
        font-weight: 400;
        background: transparent;
        color: rgb(121, 121, 121);

        & :not(:last-child) {
          margin-right: 2px;
        }
      }

      .seller-list-item {
        .title {
          font-size: 12px;
          font-weight: 500;
          padding: 1px 10px 0 0;
        }

        .item-text {
          display: flex;
          align-items: center;
          font-size: 13px;
        }
      }

      .label {
        font-weight: 400;
        background: transparent;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    min-width: 200px;
    position: absolute;
    right: 105%;
    z-index: 1;
    text-align: left;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}

.product-tag {
  display: flex;
  justify-content: center;
  width: 100px;
  padding: 2px;
  border-radius: 15px 0;
  font-size: 13px;
  background-color: rgba($primary, 0.15);
  color: $primary;

  &.yellow {
    background-color: rgba(#ffaa23, 0.15) !important;
    color: #ffaa23 !important;
  }
}

.loading-wrapper {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  z-index: 2;
}

.pending-notification {
  margin: 0 10px;
  border: 1px solid $primary;
  border-radius: 3px;
  font-size: 12px;
  position: relative;

  .moving-progress-bar {
    height: 100%;
    background-color: rgba(147, 0, 252, 0.4);
    position: absolute;
  }
}

.nav-search-container {
  background: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 2px 4px 0px;
  display: inline-flex;
  border-radius: 24px;
  height: 36px;

  .nav-search {
    display: flex;
    width: 100%;
    border-radius: 24px;
  }

  input {
    width: 100%;
    margin: 0 0 0 24px;
    border: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .input-dropdown {
    width: 100%;
    position: absolute;
    margin: 0 0 0 18px;
    border-radius: 0 0 2px 2px;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
    color: #000;
    z-index: 9;
    display: none;
    top: 36px;
    box-shadow: 2px 3px 5px -1px rgba(0, 0, 0, 0.5);

    &.active {
      display: block;
    }

    .input-dropdown-list {
      padding: 10px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active {
        background-color: #f0f0f0;
      }

      .primary-text {
        font-size: 12px;
        padding-right: 4px;
      }

      .secondary-text {
        font-size: 11px;
      }
    }
  }
}

.map-suggestion {
  position: absolute;
  z-index: 3;
  width: 260px;
  margin: 5px;
  box-shadow: "#00000033";
  border-radius: 4px;

  input {
    margin: 0 16px;
  }

  .input-dropdown {
    margin: 1px 0 0 0;
    font-size: 12px;
  }
}

.seller-card-wrapper {
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 320px;
  min-width: 280px;
  margin: 0 8px;
  background: white;
  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.add-to-cart {
    border: none;
    box-shadow: none;
    position: absolute;
    margin: 0;
    z-index: 3;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .add-to-cart-button {
    width: 100%;
    border-radius: 2px;
    border: 1px solid $primary;
    color: $primary;
    font-size: 16px;
    padding: 8px;
    transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out,
      background 0.2s ease-in-out;
  }

  &:hover {
    border: solid 1px white;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);

    .add-to-cart-button {
      box-shadow: rgba(162, 7, 255, 0) 0px 2px 2px 0px;
      color: white;
      background: linear-gradient(101deg, $primary, $primary-dark);
    }
  }

  &.no-hover {
    &:hover {
      border: solid 1px rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
  }

  .seller-card {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .cart-actions {
      border-top: none;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
    }

    .secondary-text {
      font-size: 13px;
      opacity: 0.95;
    }

    .secondary-data {
      display: flex;
      padding: 10px 0;

      &>* {
        margin: 0 5px;
      }

      & :first-child {
        margin-left: 0;
      }

      & :last-child {
        margin-right: 0;
      }
    }

    .price-list-wrapper {
      display: flex;

      .price-list {
        padding: 14px 0px;
        width: 90px;

        .price {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.13;
          text-align: center;
        }

        .quantity {
          font-size: 12px;
          line-height: 1.5;
          opacity: 0.7;
          text-align: center;
        }
      }

      &.add-to-cart-modal {
        .price-list {
          background: rgba(255, 0, 0, 0.3);
          padding: 4px;
          margin: 10px 6px;
          border-radius: 2px;

          &:first-child {
            margin-left: 12px;
          }

          &:last-child {
            margin-right: 12px;
          }

          &.active {
            background: white;

            .price {
              color: $primary;
              opacity: 1;
            }

            .quantity {
              color: $primary;
            }
          }
        }

        .price {
          font-size: 14px;
          color: white;
          opacity: 0.8;
          font-weight: 500;
        }

        .quantity {
          font-size: 10px;
          color: white;
          opacity: 1;
        }
      }
    }

    .add-to-cart-wrapper {
      padding: 18px 0 8px;

      .cart-action {
        display: flex;
        justify-content: space-between;
        padding: 0 36px;

        .title-text {
          font-size: 10px;
          letter-spacing: 2px;
          color: #7d7d7e;
        }

        .title-number {
          font-size: 18px;
        }
      }
    }
  }



}

.product-data-list {
  display: flex;
  margin-top: 24px;

  .data-list-header {
    color: #878787;
    width: 110px;
    padding-right: 10px;
    font-weight: 500;
  }
}

.product-highlight {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    padding: 0 0 8px 16px;
    position: relative;
    line-height: 19px;
    width: 300px;

    .key {
      font-weight: 500;
      color: #484848;
    }

    &::after {
      content: "";
      height: 6px;
      width: 6px;
      position: absolute;
      top: 6px;
      left: 0;
      border-radius: 50%;
      background: #c2c2c2;
    }
  }

  &.w_180 {
    li {
      width: 180px;
    }
  }
}

.fadeIn {
  animation: fadein 0.2s;
}

.cart-container {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: white;

  .title {
    padding: 13px;
    font-size: 16px;
  }

  .list {
    border-top: 1px solid rgba(120, 130, 140, 0.13);

    .list-element {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }

      &.b-t {
        padding: 16px;
      }
    }

    .checkout-element {
      padding: 16px;
      display: flex;
      flex-flow: column;

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }
    }

    .cart-element {
      padding: 16px;
      border-top: 1px solid rgba(120, 130, 140, 0.13);

      &:first-child {
        border-top: none;
      }

      &.active {
        border: 1px solid $primary;
        padding: 16px 15px 15px 15px;
      }
    }
  }
}

.custom-nav-dropdown {
  background: white;
  position: absolute;
  z-index: 0;
  margin-top: 0px;
  border: 1px solid #ddd;
  border-radius: 2px;
  color: black;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.08);
  opacity: 0;
  display: none;

  &.active {
    opacity: 1;
    z-index: 1024;
    display: block;
  }

  &.pointer-top::before {
    content: "";
    position: absolute;
    top: -8px;
    right: calc(50% - 8px);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}

.nav-button {
  margin: 0 8px;
  padding: 0 4px;
  color: #fff;
  border: 1px solid transparent;
  cursor: pointer;

  .primary-text {
    font-size: 14px;
    font-weight: 600;
  }

  .secondary-text {
    font-size: 12px;
  }

  &.active {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 2px;
  }
}

.nav-list {
  margin: 0 16px;
  border-top: 1px solid #ddd;

  &:first-child {
    border: none;
  }

  .nav-list-item {
    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }

    padding: 4px 0;
  }
}


//-Footer---//
.footer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  height: 300px;

  .footer {
    display: flex;
    flex-flow: column;
    max-width: 1366px;
    min-width: 1128px;
    width: 100%;
    padding: 20px;

    .logo {
      display: flex;
      flex: 1;
      align-items: center;
      color: white;

      img {
        height: 35px;
      }

      .logo-text {
        padding: 0px 4px;
        font-size: 30px;
        font-weight: 300;
      }
    }

    .header-text {
      display: flex;
      align-items: center;
      flex: 2;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.03em;
    }

    .footer-header {
      padding: 20px;
      border-bottom: 2px solid color.adjust($primary, $lightness: 20%);
      ;
      display: flex;
      align-items: center;
    }

    .footer-button {
      margin-left: 30px;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.03em;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 4px;
      padding: 6px 14px;
      cursor: pointer;
    }

    .footer-body {
      padding: 20px;
      display: flex;

      .footer-body-left {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .footer-body-right {
        display: flex;
        justify-content: space-between;
        flex: 2;
        font-size: 14px;
        letter-spacing: 0.03em;

        .flex-row {
          display: flex;
          flex: 1;
          flex-flow: column;

          &>* {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

.upload-list {
  display: flex;
  flex-flow: column;

  .upload-list-item {
    display: flex;
    align-items: flex-start;
    padding: 8px 0;

    &:first-child {
      padding-top: 0;
    }

    .step {
      font-size: 12px;
      font-weight: 500;
      width: 50px;
      margin-right: 8px;
    }

    .title {
      width: 150px;
      font-size: 13px;
      margin-right: 8px;
    }

    .option {
      margin-right: 8px;
    }
  }
}

.mini-tag {
  margin-left: 5px;
  padding: 3px 6px;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.05em;
  background: #eee;
  border-radius: 2px;
  text-transform: uppercase;
}

.select-days {
  .days {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 50px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    cursor: pointer;

    &.selected {
      background: $primary;
      color: white;
      font-weight: 500;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
      border-right: 1px solid #fff;
    }

    &:first-child {
      border-left: 1px solid #ddd;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &.selected {
        border-left: 1px solid $primary;
      }
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.add-location-modal {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border: 1px solid #eee;

  .modal-header {
    display: flex;
    background: #fafafa;
    padding: 15px;

    .search-icon {
      font-size: 18px;
    }

    input {
      font-size: 12px;
      background: transparent;
      border: none;
      width: 100%;
      margin-left: 5px;
    }
  }

  .modal-body {
    display: flex;
    max-height: 256px;
    overflow: auto;
    padding-bottom: 8px;
    padding-top: 8px;

    .modal-body-container {
      overflow: hidden;
      display: flex;
      width: 100%;
      height: 240px;

      .body-container-list {
        display: flex;
        flex-flow: column;
        width: 222px;
        border-right: 1px solid rgba(0, 0, 0, 0.08);
        position: relative;

        &:nth-child(3) {
          border-right: none;
        }
      }

      .search-container-list {
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;

        .list-items {
          padding: 10px 16px;
          border-bottom: 1px solid #eee;
        }

        .list-header {
          padding-top: 8px;
          padding-bottom: 4px;
        }
      }

      .list-header {
        position: sticky;
        top: 0;
        color: #000;
        font-size: 10px;
        font-weight: 600;
        padding: 0px 16px;
        z-index: 100;
        background: white;
      }

      .list-items {
        color: #000;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 20px;
        height: auto;
        text-transform: capitalize;
        padding: 4px 16px;
        cursor: pointer;

        .nav-enter {
          padding: 1px 5px;
          border-radius: 13px;
          background: white;
          visibility: hidden;
        }

        i {
          font-size: 18px;
          margin-right: 5px;

          &.blank {
            display: block;
          }

          &.intermediate {
            display: none;
          }

          &.checked {
            display: none;
          }
        }

        &.selected {
          background: white;
          color: $primary;

          i {
            &.blank {
              display: none;
            }

            &.intermediate {
              display: none;
            }

            &.checked {
              display: block;
            }
          }
        }

        &.intermediate {
          background: white;
          color: $primary;

          i {
            &.blank {
              display: none;
            }

            &.intermediate {
              display: block;
            }

            &.checked {
              display: none;
            }
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);

          .nav-enter {
            visibility: visible;
          }
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}

.pan-loader {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  display: inline-block;
  position: relative;
  height: 4px;
  width: 20px;
  vertical-align: middle;
  font-size: 0;
  animation: ls-intro 0.1s cubic-bezier(0.4, 0, 0.2, 1) 0s 1 normal forwards;

  .pan-loader-node {
    display: block;
    height: 4px;
    width: 16px;
    position: absolute;
    left: -6px;
    top: -6px;

    &:nth-child(1):before {
      left: 0;
      animation-delay: 0.1s;
    }

    &:nth-child(1):after {
      left: 8px;
      animation-delay: 0.25s;
    }

    &:nth-child(2):before {
      left: 16px;
      animation-delay: 0.4s;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      background: $primary;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      transform: scale(0.25);
      animation: ls-pulse 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s infinite normal;
    }
  }
}

.active-trip-tag {
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.05em;
  background: green;
  padding: 2px 3px;
  margin-left: 3px;
  border-radius: 3px;
  color: white;
}

.custom-select {
  padding: 0 5px;
  border: 1px solid #ddd;
  background: white;
}

.tree-view {
  .tree-view_children {
    margin-left: 30px;
  }

  .node {
    color: $primary;
    cursor: pointer;
  }

  .node-label {
    font-size: 11px;
    color: $primary;
    font-weight: 500;
  }

  &.no-arrow {
    .tree-view_arrow::after {
      content: "--";
    }

    .tree-view_arrow-collapsed {
      transform: rotate(0deg);
    }

    .node {
      font-weight: 500;
      color: inherit;
      cursor: inherit;
    }
  }
}

.codemirror-jsx {
  width: 100%;

  &.small {
    flex: 1;
    display: flex;

    .cm-editor {
      padding: 10px;
      flex: 1;
      height: auto;
    }
  }

  .cm-editor {
    height: calc(100vh - 66px);
    font-size: 11px;
  }
}

.call-center-button {
  position: relative;
  display: inline;
  border-bottom: 1px dotted black;
}

.avatar {
  border-radius: 500px;

  img {
    border-radius: 500px;
    width: 100%;
  }
}

.opacity {
  opacity: 0.5;
}