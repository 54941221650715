@use 'sass:color';

@mixin app-theme($bg, $bg-dark, $color) {

  .text-theme,
  .text-theme-hover a:hover,
  .text-theme-hover .active>a {
    color: $bg !important;
  }

  .bg-theme {
    background: $bg !important;
  }

  .bg-theme-gradient {
    background: linear-gradient($bg, $bg-dark) !important;
  }

  .bg-theme-gradient-108 {
    background: linear-gradient(108deg, $bg, $bg-dark);
  }

  .b-theme {
    border-color: $bg !important;
  }

  /* style.scss  changes */


  .product-image-wrapper {
    .image-list {
      .single-image {
        &.active {
          border: 2px solid $bg;
        }
      }
    }
  }

  .link-on-hover {
    &:hover {
      color: $bg;
    }
  }

  .custom-link {
    color: $bg;

    &:hover {
      color: $bg;
    }
  }

  .link {
    color: $bg;
  }

  .button-circle {
    &:hover {
      color: $bg;
    }
  }

  .scrollbar {
    &.theme {
      &::-webkit-scrollbar-thumb {
        background-color: $bg;
      }
    }
  }

  .pointer-text {
    &:hover {
      color: $bg;
    }
  }

  .md-custom-input {
    :focus {
      outline: none;
      border: 1px solid $bg;
    }

    :focus~label {
      color: $bg;
    }
  }

  .pagination-web {

    .paginator-arrow {
      color: $bg;
    }

    li:hover {
      color: $bg;
    }

    .active>a,
    .active>span {
      background-color: $bg !important;
      border-color: $bg !important;
    }
  }

  .nav-horizontal-md {
    li:not(.active):hover {
      color: $bg;
    }

    .active {
      color: $bg;
    }
  }

  .nav-enclosed {
    li:not(.active):hover {
      color: $bg;
    }

    li:not(.active) {
      color: $bg;
    }
  }

  .nav-header {
    .active {
      background: $bg;
    }
  }

  .option-list {
    .active {
      border: 1px solid $bg; // box-shadow: 0px 0px 3px 1px rgba(164, 7, 255, 0.5);
    }
  }

  .profile-list {
    ul {
      li {
        .content {
          .edit {
            i {
              color: $bg;
            }
          }
        }
      }
    }
  }

  .ui-check {
    &.tick {
      input:checked+i {
        color: $bg;
      }

      input:indeterminate+i {
        color: $bg;
      }
    }
  }

  .hover-link:hover {
    color: $bg;
  }

  .aggregation-containers {
    .aggregation-header {
      .header-text {
        .clear-all-text {
          color: $bg;
        }
      }
    }

    .aggregation-list {
      .aggregation-items {
        .more-item {
          .item-text {
            color: $bg;
          }
        }
      }
    }
  }

  .seller-profile {
    .close-button {
      background: $bg;
    }
  }

  .product-tag {
    background-color: rgba($bg, 0.15);
    color: $bg;
  }

  .pending-notification {
    border: 1px solid $bg;

    .moving-progress-bar {
      background-color: rgba(147, 0, 252, 0.4);
    }
  }

  .seller-card-wrapper {
    .add-to-cart-button {
      border: 1px solid $bg;
      color: $bg;
    }

    &:hover {
      .add-to-cart-button {
        color: white;
        background: linear-gradient(101deg, $bg, $bg-dark);
      }
    }

    .seller-card {
      .price-list-wrapper {
        &.add-to-cart-modal {
          .price-list {
            background: color.adjust($bg, $lightness: 40%);

            &.active {
              .price {
                color: $bg;
              }

              .quantity {
                color: $bg;
              }
            }
          }
        }
      }
    }

  }

  .cart-container {
    .list {
      .cart-element {
        &.active {
          border: 1px solid $bg;
        }
      }
    }
  }

  .footer-wrapper {
    .footer {
      .footer-header {
        border-bottom: 2px solid color.adjust($bg, $lightness: 20%);
      }
    }
  }

  .add-location-modal {
    .modal-body {
      .modal-body-container {
        .list-items {
          &.selected {
            color: $bg;
          }

          &.intermediate {
            color: $bg;
          }
        }
      }
    }
  }

  .pan-loader {
    .pan-loader-node {

      &::before,
      &::after {
        background: $bg;
      }
    }
  }

  /* chat.scss  changes */

  .chat-search {
    .list-search {
      .icon-search-morph {
        .ta-arrow-back {
          color: $bg;
        }
      }
    }
  }

  .spinner-container {
    .spinner-path {
      &.theme {
        stroke: rgba($bg, 0.7);
      }
    }
  }

  /* frontpage.scss  changes */



  .tree-view {
    .node {
      color: $bg;
    }

    .node-label {
      color: $bg;
    }

    &.no-arrow {
      .node {
        color: inherit;
      }
    }
  }

  .select-days {
    .days {
      &.selected {
        background: $bg;
        border-top: 1px solid $bg;
        border-bottom: 1px solid $bg;
      }

      &:first-child {
        &.selected {
          border-left: 1px solid $bg;
        }
      }
    }
  }

  .pagination>.active>a,
  .pagination>.active>span {
    color: white !important;
    background-color: $bg !important;
    border-color: $bg !important;
  }
}