@use "mixins.scss" as *;
@use "variables.scss" as *;

.theme-red {
  @include app-theme($red-500, #ba000d, $dark-white);
}

.theme-pink {
  @include app-theme($pink-500, #b0003a, $dark-white);
}

.theme-purple {
  @include app-theme($purple-500, #6a0080, $dark-white);
}

.theme-deep-purple {
  @include app-theme($deep-purple-500, #320b86, $dark-white);
}

.theme-indigo {
  @include app-theme($indigo-500, #002984, $dark-white);
}

.theme-blue {
  @include app-theme($blue-500, #0069c0, $dark-white);
}

.theme-light-blue {
  @include app-theme($light-blue-500, #007ac1, $dark-white);
}

.theme-cyan {
  @include app-theme($cyan-500, #008ba3, $dark-white);
}

.theme-teal {
  @include app-theme($teal-500, #00675b, $dark-white);
}

.theme-green {
  @include app-theme($green-500, #087f23, $dark-white);
}

.theme-light-green {
  @include app-theme($light-green-500, #5a9216, $dark-white);
}

.theme-lime {
  @include app-theme($lime-500, #99aa00, $dark-white);
}

.theme-yellow {
  @include app-theme($yellow-500, #c8b900, $dark-white);
}

.theme-amber {
  @include app-theme($amber-500, #c79100, $dark-white);
}

.theme-orange {
  @include app-theme($orange-500, #c66900, $dark-white);
}

.theme-deep-orange {
  @include app-theme($deep-orange-500, #c41c00, $dark-white);
}

.theme-brown {
  @include app-theme($brown-500, #4b2c20, $dark-white);
}

.theme-grey {
  @include app-theme($grey-500, #707070, $dark-white);
}

.theme-blue-grey {
  @include app-theme($blue-grey-500, #34515e, $dark-white);
}

.theme-black {
  @include app-theme(black, black, $dark-white);
}