@use "variables.scss" as *;

.spinner-container {
  animation: rotate 2s linear infinite;
  z-index: 2;

  .spinner-path {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: #acb9bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;

    &.highlight {
      stroke: rgba(27, 154, 89, 0.7);
    }

    &.theme {
      stroke: rgba($primary, 0.7);
    }

    &.white {
      stroke: white;
    }
  }
}

.none {
  display: none;
}

.active>.inline,
.active>.auto .inline {
  display: none;
}

.active>.none,
.active>.auto .none {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.rounded,
.circle {
  border-radius: 500px;
}

.clear {
  display: block;
  overflow: hidden;
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.light {
  background-color: $light;
}

.white {
  background-color: #fff;
}


.black {
  color: $black-color;

  .bg {
    background-color: $black;
  }
}

.dark {
  color: $dark-color;

  .bg {
    background-color: $dark;
  }
}

.grey {
  color: $grey-color;

  .bg {
    background-color: $grey;
  }
}

.primary {
  color: $primary-color;

  .bg {
    background-color: $primary;
  }
}

.warn {
  color: $warn-color;

  .bg {
    background-color: $warn;
  }
}

.success {
  color: $success-color;

  .bg {
    background-color: $success;
  }
}

.info {
  color: $info-color;

  .bg {
    background-color: $info;
  }
}

.warning {
  color: $warning-color;

  .bg {
    background-color: $warning;
  }
}

.danger {
  color: $danger-color;

  .bg {
    background-color: $danger;
  }
}

.label {
  display: inline-block;
  padding: .25em .5em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: bold;
  background-color: $label-bg;
  color: #fff;
  border-radius: .2rem;
}

.list {
  padding-left: 0;
  padding-right: 0;
  border-radius: $border-radius-base;
}

.list-items {
  display: block;
  position: relative;
  padding: 12px 16px;

  &:last-child .list-body:after {
    border: none;
  }

  &.active {
    background-color: $active-color;
  }
}

.list-group {
  border-radius: $border-radius-base;
  background: #fff;
}

.list-group-item {
  border-color: $list-group-border;
  background: transparent;
  padding: 12px 16px;
  background-clip: padding-box;

  &.media {
    margin-top: 0;
  }

  &.active,
  &.active:hover,
  &.active:focus {
    color: inherit;
    background-color: $min-black;
    border-color: $list-group-border;
    border-bottom-color: $list-group-border;

    a {
      color: inherit;
    }
  }

  &:first-child {
    border-top-color: $border-color;
  }

  &:last-child {
    border-bottom-color: $border-color;
  }
}

a.list-group-item {
  color: inherit;

  &:hover,
  &:focus,
  &.hover {
    color: inherit;
    background-color: $min-black;
  }
}

.app-nav {
  display: block !important;
  opacity: 1;
  overflow: hidden;
  position: fixed !important;
  z-index: 1030;
  float: left;
  height: 100%;
  width: $aside-width;

  >* {
    position: fixed;
    top: 0;
    bottom: 0;
    width: inherit;
    overflow: hidden;
  }
}

.app-content {
  box-shadow: none;
  margin-left: $aside-width;
  left: $aside-width;
  padding: 0;
}

.row {
  margin-left: -12px;
  margin-right: -12px;

  [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.navside {
  border: inherit;

  ul {
    padding-left: 0;
    padding-right: 0;
    list-style: none;
  }

  .nav {
    border: inherit;

    li {
      border: inherit;
      position: relative;

      li {
        a {
          line-height: $aside-nav-sub-height;

          .nav-text {
            padding: calc(($aside-nav-sub-height - 1.125rem) / 2) 0;
          }
        }
      }

      a {
        padding: 3px 1rem;
        line-height: $aside-nav-height;
        position: relative;

        &:hover,
        &:focus {
          background-color: $min-white;
        }
      }

      &.active {
        >a {
          background-color: transparent;

          .nav-caret i {
            rotate: 180deg;
          }
        }
      }
    }
  }

  .nav-header {
    padding: 0 1rem;
    line-height: $aside-nav-height;

    span {
      opacity: 0.4;
    }

    &:hover,
    &:focus {
      background-color: transparent !important;
    }

    &:after {
      display: none;
    }
  }
}

.nav-text {
  display: block;
  line-height: 1.125rem;
  padding: calc(($aside-nav-height - 1.125rem) / 2) 0;

  span,
  small {
    display: block;
  }

  .navside & {
    opacity: 0.65;
  }

  .navside a:hover>&,
  .navside a:focus>&,
  .navside .active>a>& {
    opacity: 1;
  }
}

.nav-icon {
  margin-right: $aside-nav-icon-margin;
  top: 0;
  width: $aside-nav-height;
  text-align: center;
  border-radius: 100%;

  i {
    position: relative;
    line-height: 1;

    svg,
    img {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .navside & {
    opacity: 0.35;
  }

  &.no-fade {
    opacity: 1 !important;
  }

  .navside a:hover>&,
  .navside a:focus>&,
  .navside .active>a>& {
    opacity: 1;
  }

}

.nav-label {
  float: right;
  margin-left: 1rem;
  font-style: normal;
  z-index: 1;

  .label {
    vertical-align: 1px;
  }
}

.nav-caret {
  float: right;
  margin-left: 1rem;
  opacity: 0.45;

  i {
    transition: transform 0.3s ease-in-out;
  }

}

.nav-sub {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease 0s;

  .active>& {
    max-height: 50rem;
  }

  li a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.nav-color {
  .nav>li>a {
    >* {
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 1rem;
      width: $aside-nav-height;
      height: 100%;
      border: calc($aside-nav-height / 2) solid transparent;
      transition: width 0.4s ease 0s;
    }

    &[class*="border-"]:before,
    &[class*=" border-"]:before {
      border-color: inherit;
    }
  }

  .nav>li.active>a:before {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.nav-stacked {
  .nav {
    >li {
      width: 100%;

      &:not(.nav-header) {
        padding: $aside-nav-stacked-margin 0.5rem;
      }

      >a {
        line-height: $aside-nav-stacked-height;
        padding: 0 0.5rem;

        .nav-icon {
          width: $aside-nav-stacked-height;
        }

        .nav-text {
          padding: calc(($aside-nav-stacked-height - 1.125rem) / 2) 0;
        }

        &:before {
          left: 0.5rem;
          border-radius: 3px;
          width: $aside-nav-stacked-height;
          border-width: calc($aside-nav-stacked-height / 2);
        }
      }
    }

    li {
      a {
        border-radius: 3px;
      }
    }
  }

  &.nav-stacked-rounded {

    .nav>li>a,
    .nav>li>a:before {
      border-radius: 500px;
    }

    .nav li li a,
    .nav li.active {
      background: transparent;
    }
  }
}

.ui-check {
  position: relative;
  padding-left: 20px;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked+i {
      &:before {
        left: calc(($ui-check-size - $ui-check-sign-size)/2);
        top: calc(($ui-check-size - $ui-check-sign-size)/2);
        width: $ui-check-sign-size;
        height: $ui-check-sign-size;
        background-color: $ui-check-sign-color;
      }
    }

    &:checked+span .active {
      display: inherit;
    }

    &[type="radio"]+i {

      &,
      &:before {
        border-radius: 50%;
      }
    }

    &[disabled],
    fieldset[disabled] & {
      &+i {
        border-color: $input-border;

        &:before {
          background-color: $input-border;
        }
      }
    }
  }

  >i {
    width: $ui-check-size;
    height: $ui-check-size;
    line-height: 1;
    box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    background-clip: padding-box;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
  }

  >span {
    margin-left: -20px;

    .active {
      display: none;
    }
  }

  &.ui-check-color input:checked+i:before {
    background-color: #fff;
  }
}


.text-muted {
  color: inherit !important;
  opacity: 0.6;
}

a.text-muted {

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.text {
  font-size: 1rem;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-md {
  font-size: $font-size-md;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.b {
  border-color: $border-color;
  background-clip: padding-box;
}

.b-a {
  border: 1px solid $border-color;
}

.b-t {
  border-top: 1px solid $border-color;
}

.b-r {
  border-right: 1px solid $border-color;
}

.b-b {
  border-bottom: 1px solid $border-color;
}



.rdp-root {
  --rdp-accent-color: #3b82f6;
  --rdp-accent-background-color: #f0f0f0;
  --rdp-day_button-height: 32px;
  --rdp-day_button-width: 32px;
  --rdp-day-height: 32px;
  --rdp-day-width: 32px;
}



.json-editor {
  .cm-scroller {
    font-size: 12px !important;
  }
}

.table {

  tr.active,
  th.active {
    td {
      background-color: $min-black !important;
    }
  }
}