@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ls-pulse {
  0% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }

  15% {
    -webkit-transform: scale(0.35);
    transform: scale(0.35);
  }

  30% {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }
}

@keyframes ls-intro {
  0% {
    width: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}


@keyframes blinking {
  0% {
    background-color: #13653d;
  }

  100% {
    background-color: #22b66e;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}