.popup-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  z-index: 1000;
}

.popup {
  width: 300px;
  height: 100%;
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;

  &.gradient {
    box-shadow: inset 0 60px 85px -49px #000;
  }

  .popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 6px 8px;
    color: white;
  }

  .action-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;

    &.blinking {
      animation: blinking 1s infinite;
    }
  }

  .call-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;

    .avatar {
      width: 100px;
      height: 100px;

      img {
        object-fit: cover;
      }
    }

    .caller-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .caller {
        display: flex;
        align-items: center;
        font-size: 20px;
      }

      .call-tag {
        font-size: 10px;
        padding: 0px 2px;
        margin: 0px 2px;
        color: #fff;
        border-radius: 2px;
        color: green;
      }

      .other-caller-divider {
        font-size: 12px;
        margin: 4px 0;
      }

      .other-caller {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }

    .call-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ddd;
      padding: 8px;
      margin: 20px;
      border-radius: 50%;

      i {
        font-size: 32px;
      }
    }
  }
}