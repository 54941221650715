$dark-black: rgba(0, 0, 0, 0.87);
$light-black: rgba(0, 0, 0, 0.54);
$min-black: rgba(0, 0, 0, 0.065);

$full-white: rgba(255, 255, 255, 1);
$dark-white: rgba(255, 255, 255, 0.87);
$black-white: rgba(225, 235, 245, 0.87);
$min-white: rgba(255, 255, 255, 0.065);

$primary: #a407ff;
$primary-dark: #6c00ca;
$warn: #b07cdd;

$info: #2196f3;
$success: #22b66e;
$warning: #f3c111;
$danger: #ef193c;

$light: #F4F4F4;
$grey: #354255;
$dark: #263449;
$black: #16202f;
$white: #ffffff;

$primary-color: $dark-white;
$warn-color: $dark-white;
$success-color: $dark-white;
$info-color: $dark-white;
$warning-color: $dark-black;
$danger-color: $dark-white;
$grey-color: $black-white;
$dark-color: $black-white;
$black-color: $black-white;
$active-color: rgba(22, 32, 42, 0.15);

$body-bg: #F4F4F4;
$text-color: $dark-black;
$text-muted: $light-black;

$enable-shadows: true;
$enable-hover-media-query: true;

$border-color: rgba(120, 130, 140, 0.13);
$border-color-lt: rgba(120, 130, 140, 0.065);
$border-color-lter: rgba(120, 130, 140, 0.045);

$line-height: 1.5;

$font-size: 0.875rem;
$font-size-md: 1.125rem;
$font-size-sm: 0.80rem;

$color-percent: 3.5%;

$border-radius-base: 3px;

$aside-width: 12.5rem;
$aside-nav-height: 2rem;
$aside-nav-sub-height: 1.75rem;
$aside-nav-stacked-height: 1.75rem;
$aside-nav-stacked-margin: 0.25rem;
$aside-nav-icon-margin: 1rem;

$label-bg: rgba(120, 130, 140, 0.6);

$list-group-border: $border-color-lt;

$input-border: rgba(120, 130, 140, 0.2);


$ui-check-size: 16px;
$ui-check-sign-size: 6px;
$ui-check-sign-color: $primary;

$red-500: #f44336;
$pink-500: #e91e63;
$purple-500: #9c27b0;
$deep-purple-500: #673ab7;
$indigo-500: #3f51b5;
$blue-500: #2196f3;
$light-blue-500: #03a9f4;
$cyan-500: #00bcd4;
$teal-500: #009688;
$green-500: #4caf50;
$light-green-500: #8bc34a;
$lime-500: #cddc39;
$yellow-500: #ffeb3b;
$amber-500: #ffc107;
$orange-500: #ff9800;
$deep-orange-500: #ff5722;
$brown-500: #795548;
$blue-grey-500: #607d8b;
$grey-500: #9e9e9e;