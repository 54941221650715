@use "variables.scss" as *;

.avatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: #dfe5e7;

  .avatar-image {
    display: block;
    opacity: 0;
    position: relative;
    transition: opacity 150ms ease-out;
    width: 100%;
    height: 100%;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;

    &.is-loaded {
      opacity: 1;
    }
  }

  .avatar-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.chat-pane-header {
  display: flex;
  flex: none;
  height: 59px;
  width: 100%;
  background-color: #eee;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  z-index: 1000;
  padding-right: 16px;
  padding-left: 15px;
  justify-content: flex-end;
  align-items: center;

  &.right {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }

  .pane-list-user {
    flex: 1;
  }

  .panel-list-controls {
    flex: none;
  }

  .menu-horizontal {
    display: flex;
    align-items: center;

    .menu-horizontal-item {
      display: flex;
      border-radius: 50%;
      cursor: pointer;
      flex: none;
      margin-left: 10px;
      padding: 8px;
      position: relative;
      transition: background-color 0.18s ease;

      i {
        color: rgba(38, 50, 56, 0.55);

        &.attach-icon {
          transform: scaleX(-1) rotate(-45deg);
        }
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .chat-avatar {
    flex: none;
    margin-right: 15px;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }

  .chat-body {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chat-main,
    .header-main {
      align-items: baseline;
      line-height: normal;
      align-items: flex-start;
      text-align: left;
      margin-top: -1px;
    }

    .chat-title {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      display: flex;
      flex-grow: 1;
      text-align: left;
    }

    .chat-title-detail {
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
      line-height: 20px;
      min-height: 20px;
      display: flex;
      align-items: flex-start;

      .span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 auto;
      }
    }
  }
}

.chat-search {
  background-color: #fbfbfb;

  .list-search {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    -webkit-flex: none;
    flex: none;
    height: 49px;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    z-index: 100;
    transition: box-shadow 0.18s ease-out, background-color 0.18s ease-out;
    transition-delay: 0.12s;
    padding: 0;
    background-color: transparent;

    .icon-search-morph {
      position: absolute;
      z-index: 100;
      width: 24px;
      height: 24px;
      left: 24px;
      top: 12px;
      background: none !important;
      font-size: 15px;

      .ta-search {
        position: absolute;
        transform: rotate(0);
        transition: all 0.24s cubic-bezier(0.4, 0, 0.2, 1);
        top: 4px;
        left: 4px;
        color: rgba(38, 50, 56, 0.3);
        font-size: 18px;
      }

      .ta-arrow-back {
        position: absolute;
        opacity: 0;
        top: 4px;
        left: 4px;
        font-size: 18px;
        transform: scale(0.8) rotate(225deg);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        pointer-events: none;
        color: $primary;
      }
    }

    .input-placeholder {
      text-overflow: ellipsis;
      overflow: hidden;
      top: 50%;
      left: 65px;
      width: calc(100% - 82px);
      font-size: 13px;
      line-height: 16px;
      margin-top: -7px;
      white-space: nowrap;
      z-index: 100;
      user-select: none;
      position: absolute;
      color: #a4a4a4;
      pointer-events: none;
      font-weight: 300;
      transition: opacity 0.08s linear;
    }

    .cont-input-search {
      -webkit-align-items: center;
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #f6f6f6;
      box-sizing: border-box;
      display: -webkit-flex;
      display: flex;
      height: 34px;
      margin-left: 12px;
      margin-top: 7px;
      padding-left: 52px;
      padding-right: 54px;
      position: absolute;
      transition: 0.12s cubic-bezier(0.1, 0.82, 0.25, 1);
      width: calc(100% - 28px);

      .input-search {
        padding: 0;
        font-size: 15px;
        z-index: 1;
        text-align: left;
        user-select: text;
        line-height: 20px;
        font-size: 15px;
        min-height: 20px;
        border: none;
        width: 100%;
        outline: none;
        font-weight: normal;
      }
    }
  }

  .list-search.active {
    background-color: #fff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    border-bottom: none;

    .icon-search-morph {
      .ta-search {
        transform: rotate(135deg);
        opacity: 0;
        transition: all 0.24s cubic-bezier(0.4, 0, 0.2, 1) 0.06s;
      }

      .ta-arrow-back {
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform: scale(1.2) rotate(360deg);
      }
    }

    .input-placeholder {
      opacity: 0;
    }

    .cont-input-search {
      margin-top: 0;
      margin-left: 0;
      height: 100%;
      width: 100%;
      padding-left: 64px;
      border-color: transparent;
      transition: all 0.12s cubic-bezier(0.1, 0.82, 0.25, 1);
      border-radius: 0;
      background-color: transparent;
    }
  }
}

.chat-list {
  display: flex;
  flex-direction: column;

  .chat {
    position: relative;
    display: flex;
    height: 72px;

    .chat-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px 0 13px;

      .avatar {
        width: 49px;
        height: 49px;
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
          border-radius: 50%;
        }
      }
    }

    .chat-body {
      border-top: 1px solid #f2f2f2;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 0;
      justify-content: center;

      .chat-main {
        display: flex;

        .chat-meta {
          line-height: 14px;
          margin-top: 4px;
        }

        .chat-title {
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          flex-grow: 1;
          text-align: left;
        }
      }

      .chat-secondary {
        display: flex;
        font-size: 13px;
        line-height: 19px;
        margin-top: 3px;
        min-height: 20px;
        align-items: flex-start;
        color: rgba(0, 0, 0, 0.6);

        .chat-status {
          display: flex;
          flex-grow: 1;
          text-align: left;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .chat-meta {
        color: rgba(0, 0, 0, 0.4);
        margin-left: 6px;
        font-size: 12px;
        flex: none;
      }
    }

    &:first-child {
      .chat-body {
        border-top: none;
      }
    }

    &.unread {
      .chat-main {
        .chat-title {
          font-weight: 500;
          color: #262626;
        }

        .chat-meta {
          color: #333;
        }
      }

      .chat-secondary {
        color: #333;

        .chat-status {
          font-weight: 500;
        }
      }
    }

    &:hover {
      background-color: #f4f5f5;
      border-top-color: #f4f5f5;
    }

    &.active {
      background-color: #f4f5f5;
      border-top-color: #f4f5f5;
    }
  }

  .chat:hover:after,
  .chat.hover:after,
  .chat.active:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 0;
    border-top: 1px solid #f4f5f5;
    content: "";
  }

  .unread-count {
    box-sizing: border-box;
    height: 19px;
    min-width: 19px;
    padding: 0px 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #09d261;
    color: #fff;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    vertical-align: top;
  }
}

.timestamp {
  text-transform: capitalize;
}

.pane-chat {
  background-color: #e5ddd5;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  position: relative;

  .pane-chat-tile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.06;
    background-repeat: repeat repeat;
    background-image: url("https://res.cloudinary.com/prokure/image/upload/v1506082773/whatsapp-image_ecgp5l.png");
  }

  .pane-body {
    display: flex;
    flex: 1 1 0;
    position: relative;

    .incoming-msgs {
      position: absolute;
      right: 11px;
      bottom: 17px;
      z-index: 200;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: white;
      cursor: pointer;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
        0 2px 5px 0 rgba(0, 0, 0, 0.2);
      color: #8d8d8d;
      font-size: 24px;

      i {
        font-size: 32px;
        position: absolute;
        bottom: 2px;
      }
    }

    .pane-chat-body {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;
      z-index: 100;
      box-sizing: border-box;
      border-left: 1px solid rgba(0, 0, 0, 0.03);
      transition: background 0.3s ease-out 0.1s;
      background-color: transparent;
      user-select: text;

      .pane-chat-empty {
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 12px;
      }

      .more-messages {
        display: flex;
        flex: none;
        justify-content: center;
        padding: 0 0 24px 0;

        .more-messages-button {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          height: 44px;
          justify-content: center;
          margin: 0 auto;
          width: 44px;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06),
            0 2px 5px 0 rgba(0, 0, 0, 0.2);
        }
      }

      .message-list {
        flex: 0 0 auto;
        padding-bottom: 8px;
        transition: padding-left 0.3s cubic-bezier(0.69, 0, 0.79, 0.14);
        padding-left: 0;

        .msg {
          padding-left: 9%;
          padding-right: 9%;
          margin-bottom: 12px;
          position: relative;

          &.msg-system {
            display: flex;
            justify-content: center;
          }

          &.msg-continuation {
            margin-bottom: 2px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &:after {
            content: "";
            display: table;
            clear: both;
          }
        }

        .message {
          .shade {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 28px;
            background: linear-gradient(to top,
                rgba(0, 0, 0, 0.3),
                transparent);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .message-system {
          flex: none;
          max-width: 100% !important;
          text-align: center;
          font-size: 12.5px;
          line-height: 21px;
          display: inline-block;
          border-radius: 7.5px;
          color: rgba(69, 90, 100, 0.95);
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
          background-color: rgba(225, 245, 254, 0.92);
          padding: 5px 12px 6px 12px;
          box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
        }

        .message-card {
          max-width: 75%;
        }

        @media screen and (min-width: 1301px) {
          .message-chat {
            max-width: 65%;
          }
        }

        @media screen and (max-width: 1300px) and (min-width: 1025px) {
          .message-chat {
            max-width: 75%;
          }
        }

        @media screen and (min-width: 901px) and (max-width: 1024px) {
          .message-chat {
            max-width: 85%;
          }
        }

        @media screen and (max-width: 900px) {
          .message-chat {
            max-width: 95%;
          }
        }

        .bubble {
          padding: 6px 0 8px 0;
          padding-left: 9px;
          padding-right: 7px;
          min-width: 110px;
          box-sizing: border-box;

          .bubble-author {
            margin-bottom: 8px;
          }
        }

        .bubble-doc {
          width: 276px;
        }

        .product-container {
          display: flex;
          flex-direction: row;
          margin: -3px -4px 14px -6px;
          overflow: hidden;
          cursor: pointer;
          box-sizing: border-box;
          text-decoration: none;

          .product-card-spinner {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .product-card {
            height: 245px;
            width: 200px;
            padding: 4px;
            box-shadow: 1px 1px 3px 0 #f2f2f2;
            background-position: left bottom;
            background-repeat: no-repeat;
            background-image: linear-gradient(to top,
                #fafafa 0,
                #fdfdfd 20%,
                #fff 60%);
            border: 1px solid rgba(75, 89, 97, 0.1);
            border-radius: 6px;
            margin-left: 4px;

            .product-body {
              padding: 10px 5px 2px;
              height: 75px;
              display: flex;
              flex-flow: column;
              justify-content: center;
            }
          }

          .product-card:first-child {
            margin-left: 0px;
          }
        }

        .document-container {
          display: flex;
          flex-direction: column;
          width: 270px;
          margin: -3px 0 14px 0;
          margin-left: -6px;
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid rgba(75, 89, 97, 0.1);
          text-decoration: none;
          color: #039be5;

          .document-thumb {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            height: 100px;
            border-bottom: 1px solid rgba(75, 89, 97, 0.1);
          }

          .document-body {
            flex: 0 0 auto;
            padding-top: 13px;
            padding-bottom: 13px;
            padding-left: 19px;
            padding-right: 19px;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            font-size: 13.6px;
            line-height: 19px;
            word-wrap: break-word;
            color: #262626;
            font-family: "Open Sans", sans-serif;

            .document-icon {
              flex: none;
              margin-top: -1px;
            }

            .document-text {
              flex: 1 1 auto;
              margin: 0 10px;
              overflow: hidden;
              top: -2px;
              position: relative;
            }

            .doc-state {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              flex: none;
              width: 34px;
              height: 34px;

              .doc-state-spinner {
                position: absolute;

                .spinner-path {
                  stroke: #849982;
                }
              }
            }
          }
        }

        .document-meta {
          position: absolute;
          bottom: 3px;
          left: 8px;
          right: auto;
          font-size: 11px;
          height: 15px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.45);

          .document-meta-value:first-child:before {
            display: none;
          }

          .document-meta-value:before {
            display: inline-block;
            content: " \2022 ";
            margin: 0 4px;
            color: #cbd5da;
          }
        }

        .message-header {
          display: flex;
          font-family: "Open Sans", sans-serif;
          font-size: 12.5px;
          font-weight: 600;
          line-height: 22px;
        }

        .message-text {
          cursor: text;
          white-space: pre-wrap;
          font-size: 13.6px;
          line-height: 19px;
          word-wrap: break-word;
          color: #262626;

          &::after {
            content: " \00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
            display: inline;
          }

          a {
            color: #039be5;
            text-decoration: underline;
          }
        }

        .bubble-text .message-meta,
        .bubble-doc .message-meta,
        .bubble-placeholder .message-meta,
        .bubble-location .message-meta,
        .bubble-product .message-meta {
          bottom: 3px;
          right: 7px;
        }

        .bubble-image,
        .bubble-location {
          padding: 3px;
          z-index: 1;
          position: relative;
          max-width: 336px;

          .shade {
            left: 0;
            width: 330px !important;
            z-index: 1;
          }

          .bubble-image-author {
            padding: 3px 0 5px 6px;
          }

          .message-meta,
          .bubble-location .message-meta {
            color: rgba(255, 255, 255, 0.9);
            bottom: 6px;
            right: 8px;
            z-index: 2;
          }
        }

        .image-thumb {
          border-radius: 6px;
          overflow: hidden;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .image-thumb-body {
            flex: none;
            -webkit-transition: -webkit-filter 0.16s linear;
          }
        }

        .message-meta {
          position: absolute;
          bottom: -5px;
          right: 0;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 15px;
          height: 15px;
          white-space: nowrap;
        }

        .message-datetime {
          vertical-align: top;
          margin-top: 1px;
          display: inline-block;
        }

        .message-pre-text {
          position: absolute;
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
        }

        .tail {
          &.message-in {
            border-top-left-radius: 0px;
            border-top-right-radius: 7.5px;

            .tail-container {
              content: "";
              position: absolute;
              left: -12px;
              top: 0px;
              width: 12px;
              height: 19px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);

              &.highlight {
                opacity: 0;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAClUExURUxpcTU1NcrKyoyMjA4ODgAAAAwMDAAAAAAAANjY2Dc3N7a2tmBgYDg4OMvLywAAALm5ubi4uGRkZMrKymdnZ4yMjM3NzdnZ2b6+vrOzs7Ozs4GBgQAAALy8vImJiQ8PDwAAAGpqasnJyTExMVpaWg8PD8XFxdLS0jQ0NLKystbW1lpaWoaGhszMzM7Ozs/Pz9TU1NDQ0NfX19jY2NPT09HR0dnZ2Si/rzgAAAAtdFJOUwAw6pUSAxQBAv0zwVo26AXFxl7lY5HMfOi+mHYJz40RDAzjLlcQ0Psxv/xBiUTl04kAAACNSURBVCjP7cu5FoIwGEThUcE/ccV9FxV3jQmgvv+jUUE1qWy95XxngLL9bGqqXqbaZfkxFHbzlMN64wGscg8E0ZsDunVLQTDW/CH9Rk4BaA4cB7R6lgOGmoOEk4w/ELQdB9RGloKgo/lD1DajAMQLxwHJIaUgON4tfUA9vxxwul044Hr2gHr4IMS/3ysApV8k8vjzIswAAAAASUVORK5CYII=);
              }
            }
          }

          &.message-out {
            border-top-left-radius: 7.5px;
            border-top-right-radius: 0px;

            .tail-container {
              content: "";
              position: absolute;
              right: -12px;
              top: 0px;
              width: 12px;
              height: 19px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADAUExURUxpcXmHa4maet/4yA0aDRMTE8fhsgAAAAAAAMDXrCsxJeX/z1xzXIiYetPsvGBsVUdPPuH8zOH8zNDrvMvmtrrOpwAAAAAAABUVFRoaGtnyxLTMozQ+MMfftFBeSR8nH5aoh6q/mW9+ZN/4yMjhtRwlHAAAAIOWd+r/06C1kkNLOwsLC9z4xur/0+n/0t76x9v4xeL9y+b/z+j/0d/7yeH8yuX/zeD8ytz5xt76yOP/zeH+y+b/zuD8yd35xuf/0MY9jkkAAAAsdFJOUwBvd/ATDZIBAsMp/At/11c9yPbizHoICQwT4bY1ykkgjahl6s8bBYT6nUAWOLbtFAAAAIhJREFUKM/tzbUWwlAURNFBE9zdg0NecLf//yvKUJyUdDnl7HXXletXqmXl9wPbQ9JCcC+VJsOj2mDwovzj3osjHGNFEVxNRAj7UR1hlx+I4FbuC8HkZBE8OwnRxamdFsEmUxCCGdoI51RLBK9xVwTvjyMEbzlDMJMp7lqseNc8YNc6CGyF/a0vcmwhZbCG+kEAAAAASUVORK5CYII=);
            }

            &.highlight {
              opacity: 0;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACoUExURUxpcaG3kg8PD1JdSXeHbLPKoCo1KgAAAAAAAA4ODr7Xq7HJoFZkTi8zKgwMDKK4kZ+1kC03LXuJbr7WqwAAAL/XrFpkUgAAAHuMb7LJoHKBZae9lQAAAJ2zjsfZtwAAACwxJp2yjqW8lVVqVbLLoQ8PDyk0KVJaSbHHnr3Vqp2yjXWEarPLobbOpLrTp73Wqr3XqrPMobnSp7fPpLfQo7fPpb/Yq7bPpKsjaEcAAAAsdFJOUwDFEVqN6DABAhL96l42FMbBM5H9Bf1jA5XldugMmHwJLr7PDMwQMVfj/L+JZBaDmAAAAIpJREFUKM/tzTcSwkAQRNHG7grvvfdGuxL+/jeDTASfgCLlh/OqaxQmrSZLJb1BeB0bhstwxHBbTBniAf84pwIEVywLodCRIYh6LUMLX61IAC5XF8GpXRJC3OgaAl8LRAvXzwuhmZEhiLLW0MLP04LCx24mhP0WHry6Hy0OdFjzXZsPd9mv4d/vPQGQ9iBRfDf7rQAAAABJRU5ErkJggg==);
            }
          }
        }

        .message-in,
        .message-out {
          border-radius: 7.5px;
          position: relative;
          box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
        }

        .message-in {
          background-color: #fff;
          float: left;

          .document-body {
            background-color: #f2f2f2;
          }
        }

        .message-out {
          background-color: #dcf8c6;
          float: right;

          .document-body {
            background-color: #d1ecbc;
          }

          .document-meta-value:before {
            color: rgba(0, 0, 0, 0.3);
          }
        }

        .msg-unread {
          display: flex;
          justify-content: center;
          color: #3d3d3d;
          font-size: 12.5px;
          width: 100%;
          margin-bottom: 12px;
          padding-bottom: 5px;
          padding-top: 5px;
          padding-left: 0;
          padding-right: 0;
          box-sizing: border-box;
          text-align: center;
          text-transform: uppercase;
          background-color: rgba(255, 255, 255, 0.25);
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);

          .msg-unread-body {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: #fff;
            border-radius: 16px;
            box-sizing: border-box;
            display: inline-block;
            font-weight: 500;
            height: 33px;
            line-height: 33px;
            max-width: 90%;
            padding: 0 22px;
          }
        }
      }
    }
  }

  .pane-chat-footer {
    padding: 0;
    background-color: rgba(255, 255, 255, 0.6);
    min-height: 62px;
    order: 3;
    position: relative;
    transform: translateZ(0);
    flex: none;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;

    .block-compose {
      align-items: flex-end;
      background-color: #f5f1ee;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      max-width: 100%;
      padding: 10px 19px;
      position: relative;
      z-index: 2;

      .emoji-button-container {
        margin-right: 20px;
        margin-bottom: 4px;

        .compose-button-emoji {
          cursor: pointer;
          font-size: 26px;
          color: #8d8d8d;
          transform: rotate(270deg);
          flex: 0 0 auto;
        }

        i {
          color: rgba(38, 50, 56, 0.55);

          &.attach-icon {
            transform: scaleX(-1) rotate(-45deg);
          }
        }
      }

      .input-container {
        border-radius: 5px;
        width: inherit;
        background-color: #ffffff;
        flex: 1 1 auto;
        min-width: 0;
        border-color: #fff;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        padding-right: 16px;
        user-select: text;
        line-height: 20px;
        font-size: 14px;
        min-height: 20px;
        padding: 10px 12px;
        outline: none;
        transition: height 0.18s ease-in-out;

        .input-emoji {
          position: relative;

          .input-placeholder {
            visibility: visible;
            user-select: none;
            position: absolute;
            top: 0px;
            left: 0px;
            color: #a4a4a4;
            pointer-events: none;
            transition: opacity 0.08s linear;
            z-index: 100;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.04em;
            line-height: 20px;
            z-index: 0;
            margin-left: 2px;
          }

          .input {
            word-wrap: break-word;
            white-space: pre-wrap;
            padding: 0 2px 0 0;
            min-height: 20px;
            max-height: 100px;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: rgba(255, 255, 255, 0);
            border-left: 0.1px solid transparent;
            position: relative;
            z-index: 1;
            user-select: text;
            line-height: 20px;
            font-size: 15px;
            border: none;
            width: 100%;
            outline: none;
            color: #4b4b4b;
            text-rendering: optimizeLegibility;
          }
        }
      }
    }
  }
}

.drawer-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
  pointer-events: none;

  .flow-drawer-container {
    position: absolute;
    top: 59px;
    left: 0;
    width: 100%;
    height: calc(100% - 59px);
    overflow: hidden;
  }
}

.drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: auto;

  .drawer-header,
  .drawer-header-small,
  .drawer-header-offset,
  .drawer-header-popup {
    display: flex;
    flex: none;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 25px;
  }

  .drawer-header-offset {
    display: flex;
    align-items: center;
    background-color: #00bfa5;
    color: #fff;
    height: 49px;
    padding: 0 24px;
  }

  .drawer-title {
    display: flex;
    flex: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .drawer-title-body {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }

  .drawer-title-action {
    text-align: initial;
    height: 19px;
    display: flex;
    align-items: center;

  }

  .drawer-header-offset,
  .drawer-header-popup {
    .drawer-title-action {
      width: 48px;
      opacity: 0.7;
    }

    .drawer-title-body {
      font-weight: 500;
      font-size: 19px;
      line-height: 19px;
    }
  }

  .drawer-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 2;
  }

  .drawer-body-noscroll {
    overflow: hidden;
  }

  .media-body {
    flex: 1;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;

    .loading {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      background: #e5e5e5;
    }

    .media-body-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .media-element-container {
        flex: 1;
        position: relative;
        width: 100%;

        .media-element {
          position: relative;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          left: 20px;

          .object-fit {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: visible;

            img {
              height: 100%;
              width: 100%;
              z-index: 2;
            }
          }
        }
      }

      .media-caption {
        flex: none;
        width: 75%;
        font-family: inherit;
        font-size: 13.6px;
        line-height: 19px;
        word-wrap: break-word;
        color: #262626;
        position: relative;
      }
    }
  }

  .media-collection {
    display: flex;
    margin-top: 58px;
    height: 120px;
    background-color: #d8d8d8;
    box-sizing: border-box;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;

    .media-thumb {
      max-width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 8px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      flex: 1;
      transition: transform 75ms ease-out;
      display: flex !important;
      justify-content: center;
      align-items: center;

      .button-delete {
        position: absolute;
        top: 1px;
        right: 1px;
        opacity: 0;
        z-index: 100;
        transform: none;
        transition: transform 75ms ease-out;
        font-size: 18px;
        padding: 2px;
        color: white;
      }

      .media-thumb-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: transform 0.1s ease-out;
        z-index: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: "";
          position: absolute;
          height: 40px;
          width: 100%;
          top: 0px;
          right: 0px;
          background: linear-gradient(10deg,
              transparent 0%,
              transparent 45%,
              rgba(0, 0, 0, 0.18) 75%,
              rgba(0, 0, 0, 0.4) 100%);
          opacity: 0;
          transition: opacity 0.1s ease-out;
        }
      }

      &.active {
        .button-delete {
          transform: translateX(-3px) translateY(3px);
        }

        .media-thumb-body {
          transform: scale(0.94);
          border: 3px solid #fff;

          &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.08);
            box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
          }
        }
      }

      &:hover {
        .button-delete {
          opacity: 1;
        }

        .media-thumb-body {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    .button-add-media {
      margin-right: 0;
      flex: 0 0 100px;
    }

    .button-fill {
      align-items: center;
      background-color: #fff;
      box-sizing: border-box;
      color: #00a5f4;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      height: 100%;
      justify-content: center;
      padding: 10px;
      text-transform: uppercase;
      width: 100%;
    }
  }

  .drawer-controls {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 92px;
    right: 40px;
    opacity: 0;
    z-index: 2;
    text-align: center;

    .button-round {
      background-color: #00e676;
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      padding: 0;
      width: 60px;
      height: 60px;
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 26px;
      font-weight: 500;
      justify-content: center;
      text-transform: uppercase;
      transition: box-shadow 0.08s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.text-input {
  position: relative;
  word-break: break-word;

  .label-text {
    font-size: 16px;
    line-height: 20px;
    padding-top: 8px;
    position: absolute;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s cubic-bezier(0.1, 0.82, 0.25, 1);
    transform: translateY(24px);
  }

  .input-spacer {
    position: relative;
    height: 24px;
  }

  .media-caption-suggestions-positioner {
    position: absolute;
    width: 100%;
    height: 182px;
    bottom: 100%;
    padding-left: 10px;
    padding-right: 10px;
    left: -10px;
    overflow: hidden;
    pointer-events: none;

    .media-caption-suggestions-container {
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;

      .mentions-positioning-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }

  .input-wrapper {
    border-bottom: 1px solid rgba(38, 50, 56, 0.18);
    display: flex;
    align-items: flex-start;

    .pluggable-input {
      position: relative;
      flex: 1;
      display: flex;
      padding-right: 5px;

      .pluggable-input-placeholder {
        left: 2px;
        color: #a4a4a4;
        font-size: 15px;
        line-height: 20px;
        pointer-events: none;
        position: absolute;
        top: 6px;
        transition: opacity 0.08s linear;
        user-select: none;
        z-index: 2;
      }

      .pluggable-input-body {
        font-size: 16px;
        line-height: 20px;
        min-height: 20px;
        position: relative;
        padding: 0;
        user-select: text;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .pluggable-input-default {
      .pluggable-input-body {
        margin: 8px 0px;
        min-width: 5px;
      }
    }
  }

  &.active {
    .input-wrapper {
      border-bottom: 2px solid #00bfa5;
    }
  }
}

.drawer-media,
.drawer-edit,
.drawer-capture {
  background-color: #e5e5e5;
}

.copyable-area,
.selectable-text {
  user-select: text;
}

.ellipsify {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.media-state-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .media-state-icon {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;

    .media-state-spinner {
      left: 0;
      position: absolute;
      top: 0;

      .spinner-path {
        stroke: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .media-state-cancel-icon {
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 50%;
    height: 44px;
    margin-left: 3px;
    margin-top: 3px;
    position: relative;
    width: 44px;

    &:before {
      content: "";
      position: absolute;
      width: 46px;
      height: 46px;
      top: 0px;
      left: 0px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
    }
  }
}

.color-1 {
  color: #35cd96 !important;
}

.color-2 {
  color: #6bcbef !important;
}

.color-3 {
  color: #e542a3 !important;
}

.color-4 {
  color: #91ab01 !important;
}

.color-5 {
  color: #ffa97a !important;
}

.color-6 {
  color: #1f7aec !important;
}

.color-7 {
  color: #dfb610 !important;
}

.color-8 {
  color: #029d00 !important;
}

.color-9 {
  color: #8b7add !important;
}

.color-10 {
  color: #fe7c7f !important;
}

.color-11 {
  color: #ba33dc !important;
}

.color-12 {
  color: #59d368 !important;
}

.color-13 {
  color: #b04632 !important;
}

.color-14 {
  color: #fd85d4 !important;
}

.color-15 {
  color: #8393ca !important;
}

.color-16 {
  color: #ff8f2c !important;
}

.color-17 {
  color: #a3e2cb !important;
}

.color-18 {
  color: #b4876e !important;
}

.color-19 {
  color: #c90379 !important;
}

.color-20 {
  color: #ef4b4f !important;
}

.bg-color-1 {
  background-color: #35cd96 !important;
}

.bg-color-2 {
  background-color: #6bcbef !important;
}

.bg-color-3 {
  background-color: #e542a3 !important;
}

.bg-color-4 {
  background-color: #91ab01 !important;
}

.bg-color-5 {
  background-color: #ffa97a !important;
}

.bg-color-6 {
  background-color: #1f7aec !important;
}

.bg-color-7 {
  background-color: #dfb610 !important;
}

.bg-color-8 {
  background-color: #029d00 !important;
}

.bg-color-9 {
  background-color: #8b7add !important;
}

.bg-color-10 {
  background-color: #fe7c7f !important;
}

.bg-color-11 {
  background-color: #ba33dc !important;
}

.bg-color-12 {
  background-color: #59d368 !important;
}

.bg-color-13 {
  background-color: #b04632 !important;
}

.bg-color-14 {
  background-color: #fd85d4 !important;
}

.bg-color-15 {
  background-color: #8393ca !important;
}

.bg-color-16 {
  background-color: #ff8f2c !important;
}

.bg-color-17 {
  background-color: #a3e2cb !important;
}

.bg-color-18 {
  background-color: #b4876e !important;
}

.bg-color-19 {
  background-color: #c90379 !important;
}

.bg-color-20 {
  background-color: #ef4b4f !important;
}